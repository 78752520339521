import React from "react";
import "./header.css";
import Logo from "../assets/logo.png";

function Header() {
	return (
		<div className="header">
			<img   className="image" alt="logo" src={Logo}></img>
			<ul className="list">
				
			</ul>
		</div>
	);
}

export default Header;
