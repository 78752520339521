import React from "react";
import './about.css'

function About() {
  return (
    <div className="about">
      <h1 style={{fontSize:"3.5rem"}}>Saudi Water Authority</h1>
      <hr className="new1" />
      <br></br>
    </div>
  );
}

export default About;
