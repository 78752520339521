import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FacebookLogo from "../assets/facebook.png";
import TwitterLogo from "../assets/twitter.png";
//import LogoWhite from "../assets/logo-white.png";
import "./Footer.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Copyright() {
  return (
    <p
      style={{ fontSize: "12px", padding: "5px", color: "#999", margin: "0px" }}
    >
      Copyright © 2024. All rights reserved.
    </p>
  );
}

export default function Footer() {
  return (
     <div></div>
  );
}
